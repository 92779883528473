
import electronBridge from "@/utils/electronBridge";

import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
  methods: {
    minimize() {
      electronBridge?.send("window_action", "minimize");
    },
    maximize() {
      electronBridge?.send("window_action", "maximize");
    },
    close() {
      electronBridge?.send("window_action", "close");
    },
  },
});
